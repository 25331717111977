<template>
<div v-if="count&&loginShow">
    <MasterHeader />
    <v-container>
        <h1 class="text-center mb-10 page_title">料金設定</h1>
        <p class="text-center">料金テーブルを設定できます。</p>
        <div class="text-right">
            <v-btn x-large color="blue" dark class="mr-5 mb-3" @click="save()">保存</v-btn>
        </div>
        <div>
            <v-form ref="form">
                <div class="fees_input float-left" v-for="i in count" :key="i">
                    <div class="ma-2 pt-3 px-2 border">
                        <p class="text-center">アイテム{{i}}</p>
                        <v-text-field outlined label="サービス名" v-model="services[i-1]" type="text" :rules="requredRules" />
                        <v-text-field outlined label="台数・対象" v-model="units[i-1]" type="text" :rules="requredRules" />
                        <v-text-field outlined label="日帰り価格" v-model="fees1[i-1]" type="number" />
                        <v-text-field outlined label="１泊 価格" v-model="fees2[i-1]" type="number" />
                        <v-text-field outlined label="２泊 価格" v-model="fees3[i-1]" type="number" />
                        <div class="text-right">
                            <v-btn text color="gray" @click="deleted(i-1)">削除</v-btn>
                        </div>
                    </div>
                </div>
                <v-btn color="red" @click="count+=1" dark class="bold f-12 mt-2 float-left">＋</v-btn>
                <div class="clear"></div>
            </v-form>
        </div>
    </v-container>
</div>
</template>

<script>
import axios from "axios"
import d from "@/d"
import Login from '@/mixins/login'
import MasterHeader from '@/components/MasterHeader.vue'

export default {
    mixins: [Login],
    data() {
        return {
            fees: 0,
            services: [],
            units: [],
            fees1: [],
            fees2: [],
            fees3: [],
            count: 0,
            requredRules: [
                v => !!v || '入力必須です',
            ],
        }
    },
    components: {
        MasterHeader
    },
    async mounted() {
        document.getElementById('footerId').style.opacity = 0
        const response = await axios.post('/master/fees')
        this.fees = response.data.fees
        this.services = this.fees.map(item => item.service)
        this.units = this.fees.map(item => item.unit)
        this.fees1 = this.fees.map(item => item.fee1)
        this.fees2 = this.fees.map(item => item.fee2)
        this.fees3 = this.fees.map(item => item.fee3)

        this.count = this.services.length
        await d.sleep(50)
        document.getElementById('footerId').style.opacity = 1
    },
    methods: {
        async save() {
            this.$refs.form.validate()
            if (!this.$refs.form.validate()) {
                return false
            }
            const data = {
                service: JSON.stringify(this.services),
                unit: JSON.stringify(this.units),
                fee1: JSON.stringify(this.fees1),
                fee2: JSON.stringify(this.fees2),
                fee3: JSON.stringify(this.fees3)
            }

            await axios.post('/master/fees/save', data)
            window.location.href = "/master/fees";
        },
        deleted(val) {
            this.services.splice(val, 1)
            this.units.splice(val, 1)
            this.fees1.splice(val, 1)
            this.fees2.splice(val, 1)
            this.fees3.splice(val, 1)
            this.count -= 1
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/master/style.scss";
@import "@/assets/sass/master/fees.scss";
</style>
